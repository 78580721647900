const Offer = ({
    title,
    price,
    period,
    subtext = null,
    selected = false,
    onClick
}) => {
    let classes = "grid grid-cols-1 bg-[#181818] hover:bg-gradient-to-b hover:from-[#427DEE] hover:to-[#5C59C8] w-full p-8 rounded-lg mb-5";
    if (selected) {
        classes += ' bg-gradient-to-b from-[#427DEE] to-[#5C59C8]';
    }

    return (
        <button className={classes} onClick={onClick}>
            <div className="flex justify-between items-center w-full">
                <div className="text-base sm:text-xl text-white text-left">{title}</div>
                <div className="text-base sm:text-xl text-white text-right">${price} / {period}</div>
            </div>

            <div className="text-sm col-span-1 text-left text-white opacity-70 mt-2">
                <div dangerouslySetInnerHTML={{ __html: subtext }} />
            </div>
            {/* {subtext !== null && <div className="text-sm col-span-2 text-left text-white opacity-70 mt-2">
                {subtext}
            </div>} */}
        </button>
    );
};

export default Offer;