import { Config } from '@cleeng/mediastore-sdk';
import '@adyen/adyen-web/dist/adyen.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './index.css';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';

// Setup cleeng values
if (!localStorage.getItem('i18nextLng')) {
    Config.setLanguage(navigator.language);
}
Config.setEnvironment('production');
Config.setPublisher('955512908');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
