import { Auth, Capture as CleengCapture } from '@cleeng/mediastore-sdk';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { STEPS, useActiveStep } from './Steps';

const Capture = () => {

    useActiveStep(STEPS.ACCOUNT);

    const navigate = useNavigate();

    // Define functions to navigate away once capture is complete
    const [isCaptureComplete, setIsCaptureComplete] = useState(false);
    const onCaptureComplete = () => setIsCaptureComplete(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { isCaptureComplete && navigate('/offer-list'); }, [isCaptureComplete]);

    return (
        !Auth.isLogged() ? (
            <Navigate to="/" />
        ) : (
            <CleengCapture onSuccess={onCaptureComplete} />
        )
    );
}

export default Capture;