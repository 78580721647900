import { Auth, Register } from '@cleeng/mediastore-sdk';
import { Navigate, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { STEPS, useActiveStep } from './Steps';

const Registration = () => {

    useActiveStep(STEPS.ACCOUNT);

    const navigate = useNavigate();

    const onCleengSuccess = () => {
        return navigate('/details');
    };

    const [ clickedAlreadyHaveAccount, setClickedAlreadyHaveAccount ] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(function () { 
        if (clickedAlreadyHaveAccount) {
            window.location = 'https://www.jme.tv'; 
        }
    }, [clickedAlreadyHaveAccount]);

    return (
        Auth.isLogged() ? (
            <Navigate to={'/details'} />
        ) : (
            <Register
                onSuccess={onCleengSuccess}
                onHaveAccountClick={() => setClickedAlreadyHaveAccount(true)}
            />
        )

    );
}

export default Registration;
