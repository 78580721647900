import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { STEPS, useActiveStep } from "./Steps";
import { Auth } from "@cleeng/mediastore-sdk";
import { useTranslation } from "react-i18next";
import i18n from './translations';

const Completed = () => {

    useActiveStep(STEPS.COMPLETED);
    const { t } = useTranslation('jme', { i18n }); 

    useEffect(() => {
        if (Auth.isLogged()) {
            const timer = setTimeout(() => {
                window.location.href = "https://watch.jme.tv";
            }, 4000);

            return () => clearTimeout(timer);
        }
    }, []);

    if (!Auth.isLogged()) {
        return <Navigate to="/" />;
    }

    dataLayer.push({'event':'action.buy'}); // eslint-disable-line

    return (
        <div className="px-16">
            <div><h2 className="text-4xl text-white text-center py-10">{t('completed-title')}</h2></div>
            <div className="text-white text-center" dangerouslySetInnerHTML={{ __html: t('completed-text')}} />
        </div>
    );
};

export default Completed;
