import { useState, useEffect } from "react";
import Offer from "./Offer";
import { Navigate, useNavigate } from "react-router-dom";
import { STEPS, useActiveStep } from "./Steps";
import { Auth } from "@cleeng/mediastore-sdk";
import { useTranslation } from "react-i18next";
import i18n from './translations';

const offers = [
    {
        id: 'S342460575',
        title: 'Monthly subscription',
        price: 25,
        period: 'month',
        subtext: `<p>
        クーポンコードを利用の場合は月額プランを選択ください。<br/>
        To redeem CouponCode, select Monthly Plan.</p>`
    },
    {
        id: 'S953087857',
        title: 'Annual subscription',
        price: 250,
        period: 'year'
    }
];

/* Poor mans enum :( */
const LoadingStates = Object.freeze({
    LOADING: 1,
    READY: 2,
    ERROR: 3,
});

const OfferList = () => {

    useActiveStep(STEPS.PLAN);
    const { t } = useTranslation('jme', { i18n });

    const [selectedOffer, setSelectedOffer] = useState(null);
    const [loadingState, setLoadingState] = useState(LoadingStates.LOADING);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOffers = async () => {

            // This is now Prod only and does not take refresh tokens into consideration
            // but as Cleeng does not export the methods it's quite a chore to clone that
            // exact behaviour.
            const response = await fetch('https://mediastoreapi.cleeng.com/offers', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('CLEENG_AUTH_TOKEN')}`
                }
            });

            const data = await response.json();
            for (let offer of offers) {
                let remoteOffer = data.responseData.find(o => o.id === offer.id);
                if (remoteOffer) {

                    // This is not great, ideally this should live in state or be some map operation
                    offer.title = remoteOffer.title;
                    offer.price = remoteOffer?.price?.amount || offer.price;
                    offer.period = remoteOffer?.billingCycle?.periodUnit || offer.period;
                }
            }

            setLoadingState(LoadingStates.READY);
        };

        fetchOffers().catch(() => setLoadingState(LoadingStates.ERROR));
    }, []);

    const onContinue = () => {

        // Don't continue without offer
        if (selectedOffer === null) {
            return;
        }

        navigate(`/purchase/${selectedOffer}`);
    }

    if (!Auth.isLogged()) {
        return <Navigate to="/" />;
    }

    if (loadingState === LoadingStates.LOADING) {
        return;
    }

    if (loadingState === LoadingStates.ERROR) {
        return (
            <div className="px-16">
                <h2 className="text-4xl text-white text-center py-10">Error, please refresh</h2>
            </div>
        );
    }

    return (
        <div className="px-16">
            <div><h2 className="text-4xl text-white text-center py-10">{t('select-your-plan')}</h2></div>

            {offers.map(({ id, title, price, period, subtext }) => (
                <Offer
                    key={title}
                    title={t(title)}
                    price={price}
                    period={t(period)}
                    subtext={subtext}
                    selected={selectedOffer === id}
                    onClick={() => setSelectedOffer(id)}
                />
            ))}

            <button onClick={onContinue} className="w-full p-2 bg-[#d61619] text-white text-center rounded-md mt-4">{t('continue')}</button>
        </div>
    );
}

export default OfferList;
