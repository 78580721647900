import { Auth, Login as CleengLogin } from '@cleeng/mediastore-sdk';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { STEPS, useActiveStep } from './Steps';

function Login() {

    useActiveStep(STEPS.ACCOUNT);

    const navigate = useNavigate();

    // Define functions to navigate away once register is clicked
    const [isRegisterClicked, setIsRegisterClicked] = useState(false);
    const onRegisterIsClicked = () => setIsRegisterClicked(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { isRegisterClicked && navigate('/'); }, [isRegisterClicked]);

    // Define functions to handle successful login
    const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
    const onLoginSuccess = () => setIsLoginSuccessful(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { isLoginSuccessful && navigate('/details'); }, [isLoginSuccessful]);

    return (
        Auth.isLogged() ? (
            <Navigate to={'/details'} />
        ) : (
            <CleengLogin
                onSuccess={onLoginSuccess}
                onRegisterClick={onRegisterIsClicked}
            />
        )
    );
}

export default Login;
