
import React, { useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Config, store } from '@cleeng/mediastore-sdk';

import Registration from './Registration';
import Login from './Login';
import Capture from './Capture';
import OfferList from './OfferList';
import Steps, { STEPS, StepsContext } from './Steps';
import Payment from './Payment';
import Completed from './Completed';
import i18n from './translations';

// Route list
const router = createBrowserRouter([
    {
        path: '/',
        element: <Registration />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/details',
        element: <Capture />
    },
    {
        path: '/offer-list',
        element: <OfferList />
    },
    {
        path: '/purchase/:offerId',
        element: <Payment />
    },
    {
        path: '/completed',
        element: <Completed />
    }
]);

const App = () => {

    const [activeStep, setActiveStep] = useState(STEPS.ACCOUNT);
    const [activeLanguage, setActiveLanguage] = useState(localStorage.getItem('i18nextLng'));
    const onChangeLanguage = (e) => {
        const value = e.target.value;
        Config.setLanguage(value);
        i18n.changeLanguage(value);
        setActiveLanguage(value);
    }

    return (
        <Provider store={store}>
            <StepsContext.Provider value={{ activeStep, setActiveStep }}>
                <div className='container mx-auto max-w-[90%] sm:max-w-[80%] lg:max-w-[36rem] px-4'>
                    <div className='flex items-center justify-between'>
                        <a href='https://watch.jme.tv/'>
                            <img alt='logo' className='my-8 w-32' src='https://register.jme.tv/logo.webp' />
                        </a>
                        <div className='grid'>
                            <svg class="text-white pointer-events-none z-10 right-1 relative col-start-1 row-start-1 h-4 w-4 self-center justify-self-end forced-colors:hidden" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"></path>
                            </svg>
                            <select className='w-32 row-start-1 col-start-1 appearance-none bg-[#0e0e0e] text-white p-2 border rounded border-white' value={activeLanguage} onChange={onChangeLanguage}>
                                <option value='en'>English</option>
                                <option value='ja'>日本語</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='container lg:w-[36rem] mx-auto sm:px-4'>
                    <Steps />
                    <RouterProvider router={router} />
                </div>
            </StepsContext.Provider>
        </Provider>
    );
}

export default App;
